<script setup lang="ts">

</script>

<template>
	<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M17.8848 16.8199V16.8178L17.9034 16.7734L20.9999 1.16177V1.11221C20.9999 0.722947 20.8554 0.383249 20.5435 0.179843C20.2699 0.00121801 19.955 -0.0111721 19.7341 0.00534816C19.5284 0.0241117 19.3255 0.0656671 19.129 0.12925C19.0451 0.156003 18.9625 0.186317 18.8812 0.220111L18.8678 0.225274L1.6041 6.99755L1.59894 6.99961C1.54605 7.01644 1.4946 7.0375 1.44509 7.0626C1.32238 7.11789 1.2046 7.18352 1.09301 7.25877C0.871015 7.41159 0.448715 7.7709 0.519959 8.34085C0.578812 8.81374 0.904055 9.11317 1.12398 9.26908C1.2535 9.36052 1.39312 9.43674 1.54009 9.49624L1.57313 9.51069L1.58345 9.51379L1.59068 9.51689L4.61182 10.5339C4.60081 10.7239 4.62043 10.9149 4.67067 11.107L6.18331 16.8467C6.26595 17.1596 6.44451 17.4387 6.69391 17.6449C6.94332 17.8511 7.25105 17.974 7.57388 17.9963C7.89671 18.0186 8.21842 17.9393 8.49384 17.7694C8.76926 17.5995 8.98454 17.3476 9.10946 17.0491L11.4719 14.5236L15.5286 17.6335L15.5864 17.6583C15.955 17.8193 16.2989 17.8699 16.6138 17.8276C16.9287 17.7842 17.1786 17.6521 17.3665 17.5024C17.5839 17.3262 17.7571 17.1017 17.8724 16.8467L17.8807 16.8292L17.8838 16.823L17.8848 16.8199ZM6.16782 10.7125C6.15107 10.6489 6.15505 10.5815 6.17919 10.5202C6.20333 10.4589 6.24636 10.407 6.30205 10.3718L16.5456 3.86696C16.5456 3.86696 17.1486 3.50042 17.127 3.86696C17.127 3.86696 17.2343 3.93097 16.9112 4.23144C16.6055 4.51744 9.61023 11.2711 8.90193 11.9547C8.86348 11.9936 8.83605 12.0421 8.82242 12.0951L7.68046 16.4523L6.16782 10.7125Z" fill="#24A1DE"/>
	</svg>
</template>

<style scoped lang="scss">

</style>